 <template>
  <v-col>
    <v-row class="flex-wrap-reverse">
      <LoaderGif v-if="loading" class="cardsArea"/>
      <NoData v-else-if="this.dataFoundStatus == 'false'" :showBtn="true" :btnTxt="$t('editFilters')" :btnClicked="()=> filtersBtn(2)" class="cardsArea"/>
      <ServerProblem v-else-if="this.dataFoundStatus == 'error'" class="cardsArea"/>
      <v-col v-else class="cardsArea d-flex flex-wrap noScrollBar pl-0">
        <RealEstateCard v-for="d in filteredData" :id="d._id"
          :class="d['_id'] == estateOpened ? ' estateOpened' : ''" cardClass="mapCard" :key="d.EstateCode"
          :image="d.MainPhoto ? d.MainPhoto : ''" :title="d.Type" :unit="d.Unit" :price="d.Price" :location="d.Location"
          :code="d.EstateCode" :comments="d.comments ? d.comments : 0" :rating="d.reviews ? d.reviews : []"
          :status="d.Status" :typeExtraContent="d.Assignation" :typeName="d.Task"
          :openRealEstateSliderHandler="() => openRealEstateSlider(d)"
          :openRealEstateEditHandler="() => openEditCard(d)" :onDelete="() => onDelete(d)"
          :approvalActionHandler="approvalAction" :isApproved="d.IsApproved" :prices="d.prices" :booleanVip="
            d.characteristics && parseBoolean(d.characteristics.vip == true)
          " :RegisterationDay="d.RegisterationDay ? d.RegisterationDay : ''"
          :authObj="{ UserID: d.UserID, CustomerID: d.CustomerID, Company: d.Company, }" />
      </v-col>
      <v-col>
        <Map :locationMarkers="locationMarkers" mapHeight="70vh" mapWidth="" :zoom="10" :markerClicked="markerClicked"
          :searchByCoordinates="true" :fetchDataHandler="fetchData" />
      </v-col>
    </v-row>
  </v-col>
</template>
 
<script>
/*
Name:RealEstateCardMaps
Description: Component to render the real estate cards map
API Property:
    data: Array for the data
    estateOpened: Number for the estate id
    loading: Boolean for the loading
    dataFoundStatus: String for the data found status
    openRealEstateEditHandler: Function on open real estate edit
    openRealEstateSliderHandler: Function to open real estate
    approvalActionHandler: Function for approval action
    onDeleteHandler: Function on delete
    fetchDataHandler: Function to fetch the data

  Components Used:
    RealEstateCard
    Map
    ServerProblem
    NoData
    LoaderGif
*/
import RealEstateCard from "./RealEstateCard.vue";
import Map from "@/components/Maps/Map";
import CoreHelper from "@/helpers/Core/core.js";
import ServerProblem from "@/components/Popups/ServerProblem.vue";
import NoData from "@/components/Popups/NoData.vue";
import LoaderGif from "@/components/Popups/LoaderGif.vue";

export default {
  name: "RealEstateCardMaps",
  props: {
    data: Array,
    estateOpened: Number,
    loading: Boolean,
    dataFoundStatus: String,
    openRealEstateEditHandler: Function,
    openRealEstateSliderHandler: Function,
    approvalActionHandler: Function,
    onDeleteHandler: Function,
    fetchDataHandler: Function,
  },
  components: { 
    RealEstateCard, 
    Map,
    ServerProblem,
    NoData,
    LoaderGif
  },
  computed: {
    filteredData() {
      return this.data.filter((m) => {
        if (m.GPS && m.GPS[0] && m.GPS[1]) return m;
      });
    },
    locationMarkers() {
      return this.filteredData.map((m) => {
        try {
          return {
            position: { lat: m.GPS[0], lng: m.GPS[1] },
            data: m,
            type: m.Task,
          };
        } catch {
          return { position: { lat: null, lng: null }, data: m };
        }
      });
    },
  },
  methods: {
    parseBoolean(val) {
      return CoreHelper.parseBoolean(val);
    },
    markerClicked(marker) {
      this.openRealEstateSliderHandler(marker.data);
    },
    openRealEstateSlider(data) {
      this.openRealEstateSliderHandler(data);
    },
    openEditCard(data) {
      this.openRealEstateEditHandler(data);
    },
    onDelete(item) {
      this.onDeleteHandler(item);
    },
    approvalAction(data, action) {
      this.approvalActionHandler(data, action);
    },
    fetchData() {
      try {
        this.fetchDataHandler();
      } catch {
        true;
      }
    },
  },
};
</script>
<style scoped>
.noScrollBar::-webkit-scrollbar {
  display: none;
}

.cardsArea {
  overflow: auto;
  max-height: 70vh !important;
  max-width: 700px !important;
  min-width: 646px !important;
}

.estateOpened {
  background-color: rgba(88, 102, 155, 0.03);
  border: 2px solid #8893bf;
  mix-blend-mode: multiply;
}

@media only screen and (max-width: 1320px) {
  .cardsArea {
    max-width: 600px !important;
    min-width: 550px !important;
    /* min-width: 646px !important; */
  }
}

@media only screen and (max-width: 1180px) {
  .cardsArea {
    max-width: 350px !important;
    min-width: 350px !important;
  }
}

@media only screen and (max-width: 880px) {
  .cardsArea {
    min-height: unset !important;
    max-height: unset !important;
    /* justify-content: center !important; */
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
</style>