<template>
  <v-navigation-drawer
    width="360px"
    height="100%"
    class="filterDrawer"
    right
    fixed
    v-model="show"
    app
    temporary
  >
    <v-col>
      <v-row class="justify-between">
        <v-col v-if="moreFeatures" class="pb-0" align="left">
          <v-icon @click="toggleMoreFeatures" class="topIcons">
            mdi-chevron-left
          </v-icon>
        </v-col>
        <v-col class="pb-0" align="right">
          <v-icon @click="closeHandler" class="topIcons primaryColorOnHover">
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="filterComp">
        <v-col class="pl-0 pr-0 pb-0">
          <v-list-item class="pa-0">
            <v-expansion-panels v-model="openedPanel" accordion>
              <v-expansion-panel v-if="!moreFeatures">
                <v-expansion-panel-header style="background-color: #ebebeb">
                  <v-row>
                    <v-col>
                      <h5
                        class="
                          textDecorationBold
                          primaryColor
                          textDecorationBold
                        "
                        style="font-size: 16px !important"
                      >
                        {{ $t("propertyType") }}
                      </h5>
                    </v-col>
                  </v-row>

                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content align="left" class="panelOverflow">
                  <v-row>
                    <v-col class="pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{ $t("availableFor") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-btn-toggle v-model="available" flex multiple>
                        <v-btn
                          v-for="button in availableForList"
                          :key="button.text"
                          :value="button.id"
                          class="ma-1 btn"
                          outlined
                        >
                          {{ $t(button.text) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{
                          $t("propertyCategories")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-btn-toggle v-model="propertyCategories">
                        <v-btn
                          v-for="button in realEstateCategoriesList"
                          :key="button.text"
                          :value="button.id"
                          class="ma-1 btn"
                          outlined
                        >
                          {{ $t(button.text) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        class="textDecorationBold"
                        style="font-size: 14px !important"
                      >
                        {{ $t("allSubCategories") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      propertyCategories == undefined ||
                      propertyCategories == null
                    "
                  >
                    <v-col class="pt-0">
                      <span style="font-size: 14px">
                        {{ $t("subpropertyCategoriesLabel") }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row v-else class="pb-12">
                    <v-col class="pb-12 pt-0">
                      <v-btn-toggle
                        v-model="propertySubCategoriesSelected"
                        multiple
                      >
                        <v-btn
                          v-for="button in getSubCategoryList"
                          :key="button.text"
                          :value="button.id"
                          class="ma-1 btn"
                          outlined
                        >
                          {{ $t(button.text) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="!moreFeatures">
                <v-expansion-panel-header style="background-color: #ebebeb">
                  <h5
                    style="font-size: 16px"
                    class="textDecorationBold primaryColor"
                  >
                    {{ $t("filters") }}
                  </h5>
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content align="left" class="panelOverflow">
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("price") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="priceMin"
                        height="30"
                        dense
                        :label="$t('from')"
                        outlined
                        clearable
                        append-icon="mdi-currency-eur"
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="priceMax"
                        height="30"
                        dense
                        :label="$t('toFilter')"
                        outlined
                        append-icon="mdi-currency-eur"
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("sqft") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="areaMin"
                        height="30"
                        dense
                        :label="$t('from')"
                        outlined
                        clearable
                        append-icon="mdi-map-marker"
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="areaMax"
                        height="30"
                        dense
                        :label="$t('toFilter')"
                        outlined
                        clearable
                        append-icon="mdi-map-marker"
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("price") + " / " + $t("sqft") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="areaPriceMin"
                        height="30"
                        dense
                        :label="$t('from')"
                        outlined
                        clearable
                        append-icon="mdi-currency-eur"
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="areaPriceMax"
                        height="30"
                        dense
                        :label="$t('toFilter')"
                        outlined
                        clearable
                        append-icon="mdi-currency-eur"
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        class="textDecorationBold text-uppercase"
                        style="font-size: 14px"
                      >
                        {{
                          $t("floors")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-select
                        v-model="floorMin"
                        :items="floorsList"
                        :label="$t('from')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item }">
                          <v-row>
                            <v-col>
                              <span>
                                {{ $t(item.text) }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-select
                        v-model="floorMax"
                        :items="floorsList"
                        :label="$t('toFilter')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item }">
                          <v-row>
                            <v-col>
                              <span>
                                {{ $t(item.text) }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        class="textDecorationBold text-uppercase"
                        style="font-size: 14px"
                      >
                        {{
                          $t("bedrooms")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-select
                        v-model="roomMin"
                        :items="getRoomList"
                        :label="$t('from')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-text="text"
                        item-value="val"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-select
                        v-model="roomMax"
                        :items="getRoomList"
                        :label="$t('toFilter')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-text="text"
                        item-value="val"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        class="text-uppercase textDecorationBold"
                        style="font-size: 14px"
                      >
                        {{
                          $t("bathrooms")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-select
                        v-model="bathroomMin"
                        :items="getRoomList"
                        :label="$t('from')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-text="text"
                        item-value="val"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-select
                        v-model="bathroomMax"
                        :items="getRoomList"
                        :label="$t('toFilter')"
                        outlined
                        height="30"
                        dense
                        clearable
                        hide-details="auto"
                        item-text="text"
                        item-value="val"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        class="text-uppercase textDecorationBold"
                        style="font-size: 14px"
                      >
                        {{
                          $t("yearOfConstruction")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="yearMin"
                        height="30"
                        dense
                        label="XXXX"
                        outlined
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="yearMax"
                        height="30"
                        dense
                        label="XXXX"
                        outlined
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-checkbox
                        v-for="button in buildingStatusList"
                        :key="button.val"
                        v-model="buildingStatus"
                        :label="$t(button.text)"
                        :value="button.val"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("energyClass") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                        <v-col class="pt-0">
                          <v-btn-toggle
                            v-model="energyButton"
                            outlined
                            class="ma-1 btn"
                          >
                            <v-btn class="ma-1 btn" outlined> Όλα </v-btn>
                            <v-btn class="ma-1 btn" outlined> Κανένα </v-btn>
                          </v-btn-toggle>
                        </v-col>
                      </v-row>-->
                  <v-row>
                    <v-col class="pt-0">
                      <v-select
                        :disabled="classEnergy == -1 || classEnergy == -2"
                        v-model="classEnergy"
                        :items="energyClassList"
                        clearable
                        class="max-width-295"
                        :label="$t('chooseEnergyClass') + '...'"
                        item-value="val"
                        item-text="text"
                      >
                        <template slot="item" slot-scope="data">
                          <span
                            class="pt-3 pb-3"
                            style="width: 295px; text-align: start"
                          >
                            {{ $t(data.item.text) }}
                          </span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("generalFeatures") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-btn-toggle v-model="genericCharacters" multiple>
                        <v-btn
                          v-for="button in generalFeaturesList"
                          :key="button.val"
                          :value="button.id"
                          class="ma-1 btn"
                          outlined
                        >
                          {{ $t(button.text) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row class="pb-12">
                    <v-col class="pb-12">
                      <v-btn
                        class="ma-1 btn textDecorationBold"
                        @click="toggleMoreFeatures"
                        outlined
                      >
                        {{ $t("moreFeatures") }}
                        <v-icon right> mdi-chevron-right </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="!moreFeatures">
                <v-expansion-panel-header style="background-color: #ebebeb">
                  <h5
                    class="textDecorationBold primaryColor"
                    style="font-size: 16px"
                  >
                    {{ $t("otherFilters") }}
                  </h5>
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content align="left" class="panelOverflow">
                  <v-row>
                    <v-col class="pb-0">
                      <v-text-field
                        class="pb-5"
                        v-model="codeEstate"
                        :label="$t('propertyCode')"
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-select
                        v-model="commandType"
                        clearable
                        :items="getAssignationsList"
                        multiple
                        :label="$t('orderType')"
                        height="30"
                        item-text="text"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-row>
                            <v-col>
                              <span v-if="index == 0">
                                {{ $t(item.text) }}
                              </span>
                              <span
                                v-if="commandType.length > 1 && index == 0"
                                class="grey--text text-caption"
                              >
                                + {{ commandType.length - 1 }}
                                {{ $t("moreSelected") }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-select
                        v-model="status"
                        :clearable="status == 'Ενεργό' ? false : true"
                        multiple
                        :items="statusList"
                        :label="$t('adStatus')"
                        height="30"
                        item-text="text"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-row>
                            <v-col>
                              <span v-if="index == 0">
                                {{ $t(item.text) }}
                              </span>
                              <span
                                v-if="status.length > 1 && index == 0"
                                class="grey--text text-caption"
                              >
                                + {{ status.length - 1 }}
                                {{ $t("moreSelected") }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-autocomplete 
                        v-model="user"
                        :label="$t('agent')"
                        :items="agentsList"
                        item-value="_id"
                        item-text="fullname"
                        :filter="filterAgents"
                        return-object
                        :search-input.sync="searchAgent"
                        :loading="agentAutocompleteLoader"
                        hide-no-data
                        clearable
                        flat
                        attach
                        hide-selected
                        v-on:keyup.enter="onSetFilter"
                        :menu-props="{ offsetY: true, bottom: true }"
                      >
                        <template v-slot:selection="{ item }">
                          <!-- <span>
                                  {{ item.LastName + ' ' + item.FirstName }}
                                </span> -->

                          <v-row>
                            <v-col
                              class="flex-grow-0 pr-0"
                              v-if="item && item.Photo"
                            >
                              <v-avatar size="24">
                                <v-img :src="getUserPhoto(item.Photo)" />
                              </v-avatar>
                            </v-col>
                            <v-col>
                              {{ item.LastName + " " + item.FirstName }}
                            </v-col>
                          </v-row>
                        </template>
                        <template slot="item" slot-scope="data">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  class="flex-grow-0 pr-0"
                                  v-if="data.item && data.item.Photo"
                                >
                                  <v-avatar size="24">
                                    <v-img
                                      :src="getUserPhoto(data.item.Photo)"
                                    />
                                  </v-avatar>
                                </v-col>
                                <v-col>
                                  {{
                                    data.item.LastName +
                                    " " +
                                    data.item.FirstName
                                  }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="phone"
                        :label="$t('telephoneFilter')"
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0">
                      <h6
                        class="textDecorationBold text-uppercase"
                        style="font-size: 14px"
                      >
                        {{
                          $t("published")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-btn-toggle v-model="publishedSelected" multiple>
                        <v-btn
                          v-for="button in publishedList"
                          :key="button.text"
                          :value="button.val"
                          class="ma-1 btn"
                          outlined
                        >
                          {{ $t(button.text) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("renewalDate") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0 pr-0 pl-1 dateClearable">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            clearable
                            outlined
                            v-model="refreshDateFromFormat"
                            prepend-inner-icon="mdi-calendar"
                            class="mb-4"
                            @blur="
                              refreshDateFrom = parseDate(
                                refreshDateFromFormat,
                                'refreshDateFrom'
                              )
                            "
                        v-on:keyup.enter="onSetFilter"
                          >
                            <template v-slot:prepend-inner>
                              <v-icon class="myzk" v-bind="attrs" v-on="on"
                                >mdi-calendar</v-icon
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          locale="el"
                          v-model="refreshDateFrom"
                          scrollable
                          @input="menu = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0 pb-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0 pb-0 pl-0 pr-1 dateClearable">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        left
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            outlined
                            clearable
                            v-model="refreshDateToFormat"
                            prepend-inner-icon="mdi-calendar"
                            class="mb-4"
                            @blur="
                              refreshDateTo = parseDate(
                                refreshDateToFormat,
                                'refreshDateTo'
                              )
                            "
                        v-on:keyup.enter="onSetFilter"
                          >
                            <template v-slot:prepend-inner>
                              <v-icon v-bind="attrs" v-on="on"
                                >mdi-calendar</v-icon
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          locale="el"
                          v-model="refreshDateTo"
                          scrollable
                          @input="menu1 = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0 pt-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("registrationDate") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0 pr-0 pl-1 dateClearable">
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            outlined
                            clearable
                            v-model="createdDateFromFormat"
                            prepend-inner-icon="mdi-calendar"
                            class="mb-4"
                            @blur="
                              createdDateFrom = parseDate(
                                createdDateFromFormat,
                                'createdDateFrom'
                              )
                            "
                        v-on:keyup.enter="onSetFilter"
                          >
                            <template v-slot:prepend-inner>
                              <v-icon v-bind="attrs" v-on="on"
                                >mdi-calendar</v-icon
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          locale="el"
                          v-model="createdDateFrom"
                          scrollable
                          @input="menu2 = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0 pb-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0 pb-0 pl-0 pr-1 dateClearable">
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        left
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            outlined
                            clearable
                            v-model="createdDateToFormat"
                            prepend-inner-icon="mdi-calendar"
                            class="mb-4"
                            @blur="
                              createdDateTo = parseDate(
                                createdDateToFormat,
                                'createdDateTo'
                              )
                            "
                        v-on:keyup.enter="onSetFilter"
                          >
                            <template v-slot:prepend-inner>
                              <v-icon v-bind="attrs" v-on="on"
                                >mdi-calendar</v-icon
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          locale="el"
                          v-model="createdDateTo"
                          scrollable
                          @input="menu3 = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0 pt-0 pb-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("registrationDateProviders") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0 pr-0 pl-1 dateClearable">
                      <v-menu
                        ref="menu4"
                        v-model="menu4"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        left
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            outlined
                            clearable
                            v-model="createdProviderDateFromFormat"
                            prepend-inner-icon="mdi-calendar"
                            class="mb-4"
                            @blur="
                              createdProviderDateFrom = parseDate(
                                createdProviderDateFromFormat,
                                'createdProviderDateFrom'
                              )
                            "
                        v-on:keyup.enter="onSetFilter"
                          >
                            <template v-slot:prepend-inner>
                              <v-icon v-bind="attrs" v-on="on"
                                >mdi-calendar</v-icon
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          locale="el"
                          v-model="createdProviderDateFrom"
                          scrollable
                          @input="menu4 = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0 pb-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-0 pb-0 pl-0 pr-1 dateClearable">
                      <v-menu
                        ref="menu5"
                        v-model="menu5"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        left
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            outlined
                            clearable
                            v-model="createdProviderDateToFormat"
                            prepend-inner-icon="mdi-calendar"
                            class="mb-4"
                            @blur="
                              createdProviderDateTo = parseDate(
                                createdProviderDateToFormat,
                                'createdProviderDateTo'
                              )
                            "
                        v-on:keyup.enter="onSetFilter"
                          >
                            <template v-slot:prepend-inner>
                              <v-icon v-bind="attrs" v-on="on"
                                >mdi-calendar</v-icon
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          locale="el"
                          v-model="createdProviderDateTo"
                          scrollable
                          @input="menu4 = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0 pb-0 pt-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        class="textDecorationBold text-uppercase"
                        style="font-size: 14px"
                      >
                        {{ $t("status") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-btn-toggle v-model="statusSelected" multiple>
                        <v-btn
                          v-for="button in availabilityList"
                          :key="button.text"
                          :value="button.id"
                          class="ma-1 btn"
                          outlined
                        >
                          {{ $t(button.text) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6 class="textDecorationBold" style="font-size: 14px">
                        {{ $t("adsWith") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row class="pb-12">
                    <v-col class="pb-12 pt-0">
                      <v-btn-toggle v-model="publicCharacters" multiple>
                        <v-btn
                          v-for="button in adsWithList"
                          :key="button.id"
                          :value="button.id"
                          class="ma-1 btn"
                          outlined
                        >
                          {{ $t(button.text) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <!--
                      <v-row class="ml-n6 mr-n6">
                        <v-col class="pl-0 pr-0"> <v-divider /> </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0 pb-0">
                          <h6
                            class="textDecorationBold"
                            style="font-size: 14px"
                          >
                            {{ $t("valuation") }}
                          </h6>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0">
                          <v-select
                            v-model="rateMin"
                            :items="getRateList"
                            :label="$t('from')"
                            outlined
                            height="30"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="text"
                            item-value="val"
                          />
                        </v-col>
                        <v-col class="flex-grow-0 pl-0 pr-0">
                          <v-icon>mdi-minus</v-icon>
                        </v-col>
                        <v-col class="pt-0">
                          <v-select
                            v-model="rateMax"
                            :items="getRateList"
                            :label="$t('from')"
                            outlined
                            height="30"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="text"
                            item-value="val"
                          />
                        </v-col>
                      </v-row>
                      -->
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="moreFeatures">
                <v-expansion-panel-header style="background-color: #ebebeb">
                  <h5
                    class="textDecorationBold primaryColor"
                    style="font-size: 14px"
                  >
                    {{ $t("moreFeatures") }}
                  </h5>
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  align="left"
                  class="panelOverflowMoreFeat"
                >
                  <v-row>
                    <v-col>
                      <v-checkbox
                        v-for="button in moreFeaturesList"
                        :key="button.val"
                        v-model="moreFeaturesSelected"
                        :label="$t(button.text)"
                        :value="button.val"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0">
                      <h5 class="textDecorationBold" style="font-size: 14px">
                        {{
                          $t("heating")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h5>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-checkbox
                        v-for="button in heatingList"
                        :key="button.val"
                        v-model="heatingSelected"
                        :label="$t(button.text)"
                        :value="button.val"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0">
                      <h5
                        class="textDecorationBold text-uppercase"
                        style="font-size: 14px"
                      >
                        {{
                          $t("technicalFeaturesAndInterior")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h5>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-select
                        v-model="frames"
                        clearable
                        :items="selectBoxesList"
                        :label="$t('frames')"
                        item-text="text"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item }">
                          <v-row class="flex-grow-0">
                            <v-col style="display: flex; align-items: center">
                              {{ $t(item.text) }}
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-select
                        v-model="typeFloor"
                        clearable
                        :items="floorList"
                        :label="$t('ground')"
                        item-text="text"
                        item-value="val"
                      >
                        <template v-slot:selection="{ item }">
                          <v-row class="flex-grow-0">
                            <v-col style="display: flex; align-items: center">
                              {{ $t(item.text) }}
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list>
                            <v-list-item-title>
                              <v-row>
                                <v-col
                                  style="display: flex; align-items: center"
                                >
                                  {{ $t(item.text) }}
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-checkbox
                        v-for="button in techFeaturesAndInteriorList"
                        :key="button.text"
                        color="primary"
                        v-model="techFeaturesAndInteriorSelected"
                        :label="$t(button.text)"
                        :value="button.val"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0">
                      <h5 class="textDecorationBold" style="font-size: 14px">
                        {{
                          $t("safety")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h5>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0 pt-0">
                      <v-checkbox
                        v-for="button in safetyList"
                        :key="button.text"
                        v-model="safetySelected"
                        :label="$t(button.text)"
                        :value="button.val"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0">
                      <h5
                        class="textDecorationBold text-uppercase"
                        style="font-size: 14px"
                      >
                        {{
                          $t("view")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        }}
                      </h5>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row class="pb-12">
                    <v-col class="pb-12 pt-0">
                      <v-checkbox
                        v-for="button in chooseAViewList"
                        :key="button.text"
                        v-model="viewSelected"
                        :label="$t(button.text)"
                        :value="button.val"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-footer inset padless absolute style="background-color: #ebebeb">
      <v-row class="pt-3 pb-3">
        <v-col>
          <v-btn
            elevation="5"
            align="center"
            class="ma-1 btn"
            color="error"
            @click="onSetFilter"
          >
            {{ $t("search") }}
          </v-btn>
        </v-col>
        <v-col class="align-self-center" aling="center">
          <a
            class="text-decoration-underline errorColor"
            @click="clearFilters"
            >{{ $t("clear") }}</a
          >
        </v-col>
      </v-row>
    </v-footer>
  </v-navigation-drawer>
</template>

<script>
/*
 Name:Filters
 Description: Component for real estates filters

 API Property: 
  onSearch: Function on search
  filterType: Number for filters category opening
  close: Function to close the filters

  Endpoints Functions Called:
    -ContactRepo
      getAgents
*/
import { mapGetters, mapActions, mapState } from "vuex";
import CoreHelper from "@/helpers/Core/core";
import Repository from "@/services/Repository";
const ContactRepo = Repository.get("contacts");

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  name: "FilterComp",
  props: {
    // drawer: Boolean,
    filterType: Number,
    onSearch: Function,
    close: Function,
  },
  created() {
    this.openedPanel = this.filterType;
    this.refreshDateFrom = this.realEstateFilter.refreshDateFrom;
    this.refreshDateTo = this.realEstateFilter.refreshDateTo;
    this.createdDateFrom = this.realEstateFilter.createdDateFrom;
    this.createdDateTo = this.realEstateFilter.createdDateTo;
    this.createdProviderDateFrom =
      this.realEstateFilter.createdProviderDateFrom;
    this.createdProviderDateTo = this.realEstateFilter.createdProviderDateTo;
    this.moreFeaturesSelected = this.realEstateFilter.homeCharacters;
    this.heatingSelected = this.realEstateFilter.heatingCharacters;
    this.publishedSelected = this.realEstateFilter.published;
    this.techFeaturesAndInteriorSelected =
      this.realEstateFilter.extraCharacters;
    this.safetySelected = this.realEstateFilter.securityCharacters;
    this.viewSelected = this.realEstateFilter.viewCharacters;
    this.user && this.user["_id"]
      ? this.fetchAgentsList(this.user["_id"], "id")
      : true;
  },
  data() {
    return {
      show: true,
      openedPanel: null,
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      refreshDateFrom: null,
      refreshDateFromFormat: null,
      refreshDateToFormat: null,
      refreshDateTo: null,
      createdDateFromFormat: null,
      createdDateFrom: null,
      createdDateToFormat: null,
      createdDateTo: null,
      createdProviderDateFrom: null,
      createdProviderDateFromFormat: null,
      createdProviderDateTo: null,
      createdProviderDateToFormat: null,
      searchAgent: null,
      agentAutocompleteLoader: false,
      agentsList: [],
      modal: false,
      publishedSelected: [],
      heatingSelected: [],
      energyButton: null,
      item: null,
      moreFeaturesSelected: [],
      techFeaturesAndInteriorSelected: [],
      safetySelected: [],
      viewSelected: [],
      moreFeatures: false,
    };
  },
  watch: {
    show() {
      if (!this.show) {
        this.closeHandler();
      }
    },
    refreshDateFrom() {
      this.refreshDateFromFormat = this.formatDate(this.refreshDateFrom);
      this.onSetRefreshDateFrom(
        this.refreshDateFrom == null || this.refreshDateFrom == undefined
          ? null
          : this.refreshDateFrom
      );
    },
    refreshDateTo() {
      this.refreshDateToFormat = this.formatDate(this.refreshDateTo);
      this.onSetRefreshDateTo(
        this.refreshDateTo == null || this.refreshDateTo == undefined
          ? null
          : this.refreshDateTo
      );
    },
    createdDateFrom() {
      this.createdDateFromFormat = this.formatDate(this.createdDateFrom);
      this.onSetCreatedDateFrom(
        this.createdDateFrom == null || this.createdDateFrom == undefined
          ? null
          : this.createdDateFrom
      );
    },
    createdDateTo() {
      this.createdDateToFormat = this.formatDate(this.createdDateTo);
      this.onSetCreatedDateTo(
        this.createdDateTo == null || this.createdDateTo == undefined
          ? null
          : this.createdDateTo
      );
    },
    createdProviderDateFrom() {
      this.createdProviderDateFromFormat = this.formatDate(
        this.createdProviderDateFrom
      );
      this.onSetCreatedProviderDateFrom(
        this.createdProviderDateFrom == null ||
          this.createdProviderDateFrom == undefined
          ? null
          : this.createdProviderDateFrom
      );
    },
    createdProviderDateTo() {
      this.createdProviderDateToFormat = this.formatDate(
        this.createdProviderDateTo
      );
      this.onSetCreatedProviderDateTo(
        this.createdProviderDateTo == null ||
          this.createdProviderDateTo == undefined
          ? null
          : this.createdProviderDateTo
      );
    },
    energyButton(val) {
      if (val == 0) this.onSetClassEnergy(-1);
      else if (val == 1) this.onSetClassEnergy(-2);
      else this.onSetClassEnergy(null);
    },
    moreFeaturesSelected(val) {
      this.onSetHomeCharacters(val);
    },
    heatingSelected(val) {
      this.onSetHeatingCharacters(val);
    },
    publishedSelected(val) {
      this.onSetPublished(val);
    },
    techFeaturesAndInteriorSelected(val) {
      this.onSetExtraCharacters(val);
    },
    safetySelected(val) {
      this.onSetSecurityCharacters(val);
    },
    viewSelected(val) {
      this.onSetViewCharacters(val);
    },
    searchAgent(val) {
      val && this.fetchAgentsList(val, "splitName");
    },
  },
  computed: {
    ...mapGetters([
      "availableForList",
      "realEstateCategoriesList",
      "generalFeaturesList",
      "buildingStatusList",
      "publishedList",
      "floorsList",
      "adsWithList",
      "moreFeaturesList",
      "heatingList",
      "techFeaturesAndInteriorList",
      "safetyList",
      "chooseAViewList",
      "getRoomList",
      "energyClassList",
      "selectBoxesList",
      "floorList",
      "getAssignationsList",
      "statusList",
      "availabilityList",
      "getRateList",
    ]),
    ...mapState({
      listRealEstate: (state) => state.listRealEstate,
      realEstateFilter: (state) => state.realEstate,
      userDetails: (state) => state.auth.userDetails,
    }),

    available: {
      get() {
        return this.realEstateFilter.available;
      },
      set(value) {
        this.onSetAvailable(value);
      },
    },
    propertyCategories: {
      get() {
        return this.realEstateFilter.category?.id;
      },
      set(value) {
        if (value == undefined) {
          this.onSetCategory(null);
          this.onSetSubCategory(null);
        } else {
          this.onSetCategory(this.realEstateCategoriesList[value]);
        }
      },
    },
    propertySubCategoriesSelected: {
      get() {
        return this.realEstateFilter.subCategory;
      },
      set(value) {
        this.onSetSubCategory(value);
      },
    },
    buildingStatus: {
      get() {
        return this.realEstateFilter.buildingStatus;
      },
      set(value) {
        this.onSetBuildingStatus(value);
      },
    },
    genericCharacters: {
      get() {
        return this.realEstateFilter.genericCharacters;
      },
      set(value) {
        this.onSetGenericCharact(value);
      },
    },
    roomMin: {
      get() {
        return this.realEstateFilter.roomMin;
      },
      set(value) {
        this.onSetRoomMin(value);
      },
    },
    roomMax: {
      get() {
        return this.realEstateFilter.roomMax;
      },
      set(value) {
        this.onSetRoomMax(value);
      },
    },
    bathroomMin: {
      get() {
        return this.realEstateFilter.bathroomMin;
      },
      set(value) {
        this.onSetBathroomMin(value);
      },
    },
    bathroomMax: {
      get() {
        return this.realEstateFilter.bathroomMax;
      },
      set(value) {
        this.onSetBathroomMax(value);
      },
    },
    floorMin: {
      get() {
        return this.realEstateFilter.floorMin;
      },
      set(value) {
        this.onSetFloorMin(value);
      },
    },
    floorMax: {
      get() {
        return this.realEstateFilter.floorMax;
      },
      set(value) {
        this.onSetFloorMax(value);
      },
    },
    priceMin: {
      get() {
        return this.realEstateFilter.priceMin;
      },
      set(value) {
        this.onSetPriceMin(
          value == null || value == undefined || value == ""
            ? null
            : Number(value)
        );
      },
    },
    priceMax: {
      get() {
        return this.realEstateFilter.priceMax;
      },
      set(value) {
        this.onSetPriceMax(
          value == null || value == undefined || value == ""
            ? null
            : Number(value)
        );
      },
    },
    areaMin: {
      get() {
        return this.realEstateFilter.areaMin;
      },
      set(value) {
        this.onSetAreaMin(
          value == null || value == undefined || value == ""
            ? null
            : Number(value)
        );
      },
    },
    areaMax: {
      get() {
        return this.realEstateFilter.areaMax;
      },
      set(value) {
        this.onSetAreaMax(
          value == null || value == undefined || value == ""
            ? null
            : Number(value)
        );
      },
    },
    areaPriceMin: {
      get() {
        return this.realEstateFilter.areaPriceMin;
      },
      set(value) {
        this.onSetAreaPriceMin(
          value == null || value == undefined ? null : Number(value)
        );
      },
    },
    areaPriceMax: {
      get() {
        return this.realEstateFilter.areaPriceMax;
      },
      set(value) {
        this.onSetAreaPriceMax(
          value == null || value == undefined ? null : Number(value)
        );
      },
    },
    classEnergy: {
      get() {
        return this.realEstateFilter.classEnergy;
      },
      set(value) {
        this.onSetClassEnergy(
          value == null || value == undefined ? null : value
        );
      },
    },
    codeEstate: {
      get() {
        return this.realEstateFilter.codeEstate;
      },
      set(value) {
        this.onSetCodeEstate(
          value == null || value == undefined ? null : value
        );
      },
    },
    commandType: {
      get() {
        return this.realEstateFilter.commandType;
      },
      set(value) {
        this.onSetCommandType(
          value == null || value == undefined ? null : value
        );
      },
    },
    status: {
      get() {
        return this.realEstateFilter.status;
      },
      set(value) {
        this.onSetStatus(value);
      },
    },
    user: {
      get() {
        return this.realEstateFilter.user;
      },
      set(value) {
        this.onSetUser(value == null || value == undefined ? null : value);
      },
    },
    phone: {
      get() {
        return this.realEstateFilter.phone;
      },
      set(value) {
        this.onSetPhone(value == null || value == undefined ? null : value);
      },
    },
    statusSelected: {
      get() {
        return this.realEstateFilter.statusType;
      },
      set(value) {
        this.onSetStatusType(
          value == null || value == undefined ? null : value
        );
      },
    },
    // owner: {
    //   get() {
    //     return this.realEstateFilter.rateMin;
    //   },
    //   set(value) {
    //     this.onSetRateMin(value == null || value == undefined ? null : value);
    //   },
    // },
    rateMin: {
      get() {
        return this.realEstateFilter.rateMax;
      },
      set(value) {
        this.onSetRateMin(value == null || value == undefined ? null : value);
      },
    },
    rateMax: {
      get() {
        return this.realEstateFilter.rateMax;
      },
      set(value) {
        this.onSetRateMax(value == null || value == undefined ? null : value);
      },
    },
    yearMin: {
      get() {
        return this.realEstateFilter.yearMin;
      },
      set(value) {
        this.onSetYearMin(value == null || value == undefined ? null : value);
      },
    },
    yearMax: {
      get() {
        return this.realEstateFilter.yearMax;
      },
      set(value) {
        this.onSetYearMax(value == null || value == undefined ? null : value);
      },
    },
    frames: {
      get() {
        return this.realEstateFilter.frames;
      },
      set(value) {
        this.onSetframes(value == null || value == undefined ? null : value);
      },
    },
    typeFloor: {
      get() {
        return this.realEstateFilter.typeFloor;
      },
      set(value) {
        this.onSetTypeFloor(value == null || value == undefined ? null : value);
      },
    },
    publicCharacters: {
      get() {
        return this.realEstateFilter.publicCharacters;
      },
      set(value) {
        this.onSetPublicCharacters(
          value == null || value == undefined ? null : value
        );
      },
    },
    getSubCategoryList() {
      return this.realEstateCategoriesList[this.propertyCategories]
        .subCategoriesList;
    },
  },
  methods: {
    ...mapActions([
      "onSetAvailable",
      "onSetCategory",
      "onSetSubCategory",
      "onSetBuildingStatus",
      "onSetRoomMin",
      "onSetRoomMax",
      "onSetBathroomMin",
      "onSetBathroomMax",
      "onSetFloorMin",
      "onSetFloorMax",
      "onSetPriceMax",
      "onSetPriceMin",
      "onSetAreaMin",
      "onSetAreaMax",
      "onSetAreaPriceMin",
      "onSetAreaPriceMax",
      "onSetClassEnergy",
      "onSetCodeEstate",
      "onSetCommandType",
      "onSetStatus",
      "onSetUser",
      "onSetPhone",
      "onSetRefreshDateFrom",
      "onSetRefreshDateTo",
      "onSetStatusType",
      // "onSetOwner",
      "onSetCreatedDateFrom",
      "onSetCreatedDateTo",
      "onSetCreatedProviderDateTo",
      "onSetCreatedProviderDateFrom",
      "onSetRateMax",
      "onSetRateMin",
      "onSetYearMin",
      "onSetYearMax",
      "onSetGenericCharact",
      "onSetHomeCharacters",
      "onSetHeatingCharacters",
      "onSetPublished",
      "onSetExtraCharacters",
      "onSetSecurityCharacters",
      "onSetViewCharacters",
      "onSetframes",
      "onSetTypeFloor",
      "clearFilter",
      "onSetPublicCharacters",
    ]),
    toggleMoreFeatures() {
      this.moreFeatures = !this.moreFeatures;
      if (this.moreFeatures == true) this.openPanel(0);
    },
    clearFilters() {
      this.publishedSelected = [];
      this.heatingSelected = [];
      this.statusSelected = [];
      this.available = [];
      this.item = null;
      this.moreFeaturesSelected = [];
      this.techFeaturesAndInteriorSelected = [];
      this.safetySelected = [];
      this.viewSelected = [];
      this.energyButton = null;
      this.refreshDateFrom = null;
      this.refreshDateTo = null;
      this.createdDateFrom = null;
      this.createdDateTo = null;
      this.createdProviderDateFrom = null;
      this.createdProviderDateTo = null;
      this.clearFilter();
    },

    closeAllPanels() {
      this.openedPanel = null;
    },
    openPanel(index) {
      this.openedPanel = index;
    },
    closeHandler() {
      this.$emit("close");
    },
    onSetFilter() {
      this.onSearch("genericSearch");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "refeshDateFrom") {
          this.refreshDateFrom = null;
          this.refreshDateFromFormatted = null;
        } else if (dateName == "refeshDateTo") {
          this.refreshDateTo = null;
          this.refreshDateToFormat = null;
        } else if (dateName == "createdDateFrom") {
          this.createdDateFrom = null;
          this.createdDateFromFormat = null;
        } else if (dateName == "createdDateTo") {
          this.createdDateTo = null;
          this.createdDateToFormat = null;
        } else if (dateName == "createdProviderDateFrom") {
          this.createdProviderDateFrom = null;
          this.createdProviderDateFromFormat = null;
        } else if (dateName == "createdProviderDateTo") {
          this.createdProviderDateTo = null;
          this.createdProviderDateToFormat = null;
        }
        return null;
      }
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getUserPhoto(photo) {
      return CoreHelper.getImagePath({ path: photo });
    },
    async fetchAgentsList(val, type) {
      try {
        let filters;
        this.agentAutocompleteLoader = true;
        if (
          ![null, undefined, ""].includes(val) &&
          type == "splitName"
        ) {
            filters = [
              {
                $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }],
              },
            ];
          if (val != null) {
            if ((filters.length == 1) != undefined) {
              filters.push([]);
            }
            filters[1].push({
              ["LastName"]: {
                $regex: CoreHelper.toRegex(val.split(" ")[0]),
                $options: "i",
              },
            });
          }
        }
        let resp = await ContactRepo.getAgents(filters, { page: 0, items: 20 });
        this.agentsList = resp.data.agents.map((a) => {
          return { ...a, fullname: a.LastName + " " + a.FirstName };
        });
        this.agentAutocompleteLoader = false;
      } catch (e) {
        this.agentAutocompleteLoader = false;
        throw e;
      }
    },
    filterAgents(item, queryText, itemText) {
      if (queryText.length >= 1) {
        let queryWord = queryText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .split(" ");
        let itemWord = itemText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase();
        return queryWord.every((item) => itemWord.includes(item));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.panelOverflow {
  height: calc(100vh - 278px);
  overflow: auto;
}

.panelOverflowMoreFeat {
  height: calc(100vh - 182px);
  overflow: auto;
}

h6 {
  padding: 10px 0px 10px 0px;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}

.btn {
  text-transform: unset !important;
  color: #263573;
}

.topIcons {
  cursor: pointer;
  color: #263573;
}

.v-expansion-panel--active + .v-expansion-panel,
.v-expansion-panel--active:not(:first-child) {
  margin-top: 0px;
}

.v-text-input {
  font-size: 14px;
}

.v-btn-toggle {
  border-radius: 4px;
  display: block;
  max-width: 100%;
}

.v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 48px;
  min-height: 0;
}

.v-btn-toggle--group > .v-btn.v-btn {
  border-color: #263573 !important;
}

.v-btn-toggle > .v-btn.v-btn {
  border-radius: 5px !important;
  border-width: thin !important;
  background-color: white !important;
}

.v-input--selection-controls {
  margin: 0px 0px -16px 0px;
  padding-top: 0px;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgba(38, 53, 115, 1) !important;
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  background-color: white;
}

.v-label {
  font-size: 13px;
}

.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 35px;
}

#active-button {
  background-color: #263573 !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: #fff;
  color: #263573;
}

.primaryColor {
  color: #263573 !important;
}

.theme--light.v-btn-toggle.v-btn-toggle--group {
  background: #fff;
  color: #263573;
}

.divInputs {
  display: flex;
  padding-bottom: 10px;
}

.divInputsNoBottomPadding {
  display: flex;
  padding-bottom: 0px;
}

.v-select {
  font-size: 14px;
}

</style>
<style>

.filterComp  .v-text-field.v-text-field--solo .v-input__control input {
    font-size: 16px !important;
}
.filterComp input {
    font-size: 16px !important
  }
</style>
