<template>
  <v-col>
    <v-row id="topAreaID">
      <v-col>
        <v-row class="align-center justify-space-between topRow">
          <v-col
            class="pb-0 pr-0"
            :class="mobileBreakpoint == true ? '' : 'flex-grow-0 text-no-wrap '"
          >
            <HeaderTitle
              :title="$tc('realEstate', 2)"
              :subTitle="$t('realEstateSubHeader')"
              fontSizeTitle="24px"
              fontFamilyTitle="CenturyGothicBold"
              fontSizeSub="16px"
              fontFamilySub="CenturyGothicBold"
            />
          </v-col>
          <v-col
            class="b-0"
            :class="mobileBreakpoint == true ? '' : 'align-end'"
          >
            <v-row
              class="align-center justify-end"
              :class="
                mobileBreakpoint == true ? 'justify-center' : 'topFirstRow'
              "
            >
              <v-col v-if="isAuthAddEstate" class="flex-grow-0 pr-2">
                <v-btn class="btn-custom" @click="createBtn()" color="error">
                  <Icon
                    v-if="mobileBreakpoint == true"
                    icon="mdi:plus-thick"
                    height="25"
                    outline
                  />
                  <span v-else> {{ $t("addRealEstate") }} </span>
                </v-btn>
              </v-col>
              <v-col class="flex-grow-0 pr-2 pl-2 hideOnMedia">
                <v-btn
                  class="btn-custom"
                  @click="filtersBtn(0)"
                  key="fBtn0"
                  outlined
                  color="primary"
                >
                  {{ $t("propertyType") }}
                </v-btn>
              </v-col>
              <v-col class="flex-grow-0 pr-2 pl-2 hideOnMedia">
                <v-btn
                  class="btn-custom"
                  @click="filtersBtn(1)"
                  key="fBtn1"
                  outlined
                  color="primary"
                >
                  {{ $t("filters") }}
                </v-btn>
              </v-col>
              <v-col class="flex-grow-0 pl-2">
                <v-btn
                  class="btn-custom"
                  @click="filtersBtn(2)"
                  key="fBtn2"
                  outlined
                  color="primary"
                >
                  <Icon
                    icon="bx:filter-alt"
                    height="25"
                    outline
                    color="#263573"
                  />
                </v-btn>
              </v-col>
              <v-col class="flex-grow-0 pr-2 pl-2">
                <v-btn-toggle
                  dense
                  v-model="viewChoice"
                  group
                  tile
                  mandatory
                  color="primary"
                >
                  <v-btn
                    :id="viewChoice == 0 ? 'active-button' : ''"
                    class="mr-1"
                  >
                    <Icon
                      icon="clarity:grid-view-solid"
                      height="25"
                      :color="viewChoice == 0 ? 'white' : '#263573'"
                    />
                  </v-btn>
                  <v-btn
                    :id="viewChoice == 1 ? 'active-button' : ''"
                    class="mr-3 hideOnMedia"
                  >
                    <Icon
                      icon="ant-design:unordered-list-outlined"
                      height="30"
                      :color="viewChoice == 1 ? 'white' : '#263573'"
                    />
                  </v-btn>
                  <v-btn
                    :id="viewChoice == 2 ? 'active-button' : 'inactive-button'"
                    class="ml-3 mr-3"
                  >
                    <Icon
                      icon="eva:pin-outline"
                      height="25"
                      :color="viewChoice == 2 ? 'white' : '#263573'"
                    />
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="justify-space-between locRow">
          <v-col :cols="mobileBreakpoint ? '' : 7">
            <v-row class="justify-start HRow">
              <v-col class="flex-grow-0 HCol pb-0">
                <v-row
                  class="leftHRow"
                  :class="
                    mobileBreakpoint == true ? 'justify-center' : 'flex-nowrap'
                  "
                >
                  <v-col class="flex-grow-0">
                    <v-select
                      :items="filterArray"
                      dense
                      v-model="filterEstate"
                      item-text="text"
                      item-value="val"
                      color="primary"
                      item-color="primary"
                      style="
                        font-size: 15px;
                        font-family: CenturyGothicBold;
                        font-weight: 500;
                      "
                      height="43.54"
                      hide-details
                      outlined
                      @change="optionsRefresh"
                      :placeholder="$t('all')"
                    >
                      <template v-slot:selection="{ item }">
                        <v-row class="flex-grow-0">
                          <v-col
                            class="d-flex text-no-wrap"
                            style="align-items: center"
                          >
                            {{ $t(item.text) }}
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list>
                          <v-list-item-title>
                            <v-row>
                              <v-col style="display: flex; align-items: center">
                                {{ $t(item.text) }}
                              </v-col>
                            </v-row>
                          </v-list-item-title>
                        </v-list>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col class="flex-grow-0">
                    <v-select
                      :items="getItemsList"
                      dense
                      v-model="options.items"
                      color="primary"
                      item-color="primary"
                      style="
                        max-width: 75px;
                        font-size: 14px;
                        font-family: CenturyGothicBold;
                        font-weight: 500;
                      "
                      height="43.54"
                      @change="optionsRefresh"
                      hide-details
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pr-0 pb-0">
                <Location
                  :placeholder="$t('searchLoc') + ' Google...'"
                  :setLocation="fetchRealEstate"
                  :showNewDealList="true"
                  iconColor="primary"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pr-0 rightHRow">
            <v-row class="topSecondRow justify-end">
              <v-col class="pl-0 pr-0 pt-5 pb-0 flex-grow-0">
                <HeaderColumns
                  v-if="viewChoice == 1"
                  :onSubmit="updateHeaderCols"
                  :headers="headers"
                  :selectedHeaderColumns="headerColumns"
                />
              </v-col>
              <v-col
                class="pb-0"
                :class="
                  mobileBreakpoint == true ? '' : 'flex-grow-0 pl-0 pr-0 pt-5'
                "
              >
                <SortColumns
                  v-if="[0, 1, 2].includes(viewChoice)"
                  :title="
                    Object.keys(sort[0]).length === 0 && viewChoice == 1
                      ? $t('showSortOption')
                      : $t('sort')
                  "
                  :selectedTitle="
                    Object.keys(sort[0]).length === 0 ? null : sort[0].text
                  "
                  :signleChoice="true"
                  :columns="headers"
                  :onSubmit="updateSortCols"
                />
              </v-col>
              <v-col class="flex-grow-0">
                <v-btn
                  v-if="viewChoice == 1"
                  class="gray-btn textDecorationBold pr-3"
                  outlined
                  :height="43.58"
                  :width="180"
                  :loading="expListloader"
                  @click="exportListClicked"
                >
                  Export List
                </v-btn>
              </v-col>
              <v-col
                class="pl-0 pr-0 pb-0"
                :class="mobileBreakpoint == true ? '' : 'flex-grow-0'"
              >
                <v-pagination
                  class="main-pgt"
                  v-model="options.page"
                  :total-visible="6"
                  @input="fetchRealEstate"
                  :length="totalPages"
                >
                </v-pagination>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="left" class="d-flex flex-nowrap overflow-auto">
        <FilterTag
          v-for="(tag, index) in tagFilterArray"
          :key="index"
          :display="tag.display"
          :deleteHandler="deleteFilter"
          :attrType="tag.attrType"
          :attrHead="tag.attrHead"
          :attrIndex="tag.attrIndex"
          :attrValue="tag.attrValue"
        />
        <a
          v-if="tagFilterArray && tagFilterArray.length > 0"
          class="text-decoration-underline text-no-wrap"
          color="#43425D"
          @click="clearAllFilter"
        >
          {{ $t("clear") }}
        </a>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row
          v-if="viewChoice != 2 && (loading || dataFoundStatus != 'true')"
          justify="center"
        >
          <LoaderGif v-if="loading" />
          <NoData
            v-else-if="this.dataFoundStatus == 'false'"
            :showBtn="true"
            :btnTxt="$t('editFilters')"
            :btnClicked="() => filtersBtn(2)"
          />
          <ServerProblem v-else-if="this.dataFoundStatus == 'error'" />
        </v-row>
        <v-row
          v-else-if="viewChoice == 0 && !loading && dataFoundStatus == 'true'"
          class="noScrollBar realEstCards"
          :style="
            !mobileBreakpoint
              ? `min-height: 450px !important; max-height:${getReportHeight}px; overflow: auto;`
              : ''
          "
          :class="{ 'justify-center': mobileBreakpoint }"
        >
          <RealEstateCard
            v-for="d in data"
            :class="{ estateOpened: d['_id'] == estateOpened }"
            :id="d._id"
            :key="d.EstateCode"
            :image="d.MainPhoto ? d.MainPhoto : ''"
            :title="d.Type"
            :unit="d.Unit"
            :price="d.Price"
            :location="d.Location"
            :code="d.EstateCode"
            :comments="d.comments ? d.comments : 0"
            :rating="d.reviews ? d.reviews : []"
            :status="d.Status"
            :typeExtraContent="d.Assignation"
            :typeName="d.Task"
            :openRealEstateSliderHandler="() => openRealEstateSlider(d, true)"
            :openRealEstateEditHandler="() => onEdit(d)"
            :onDelete="() => onDelete(d)"
            :approvalActionHandler="changeStatus"
            :isApproved="d.IsApproved"
            :prices="d.prices"
            :booleanVip="
              d.characteristics && parseBoolean(d.characteristics.vip)
            "
            :RegisterationDay="d.RegisterationDay ? d.RegisterationDay : ''"
            :authObj="{
              UserID: d.UserID,
              CustomerID: d.CustomerID,
              Company: d.Company,
            }"
          />
        </v-row>
        <v-row v-else-if="viewChoice == 2">
          <RealEstateCardMaps
            :data="data"
            :estateOpened="estateOpened"
            :loading="loading"
            :dataFoundStatus="dataFoundStatus"
            :openRealEstateSliderHandler="(d) => openRealEstateSlider(d, true)"
            :openRealEstateEditHandler="onEdit"
            :onDelete="() => onDelete(d)"
            :approvalActionHandler="changeStatus"
            :fetchDataHandler="fetchRealEstate"
          />
        </v-row>
        <v-row
          v-else-if="viewChoice == 1 && !loading && dataFoundStatus == 'true'"
        >
          <CustomTable
            :headers="reportHeaderColumns"
            :fixedHeader="true"
            :tableHeight="getTableHeight"
            :itemsPerPage="options.items"
            :totalPages="totalPages"
            :templates="templates"
            :data="data"
            :supportDelete="true"
            :supportEdit="true"
            :supportView="true"
            :handleDelete="onDelete"
            :handleEdit="onEdit"
            :handleView="(d) => openRealEstateSlider(d, true)"
          />
        </v-row>
      </v-col>
    </v-row>
    <FilterRealEstate
      @close="closeDialogSimple"
      :onSearch="closeDialog"
      :filterType="filterSelection"
      v-if="dialog.type == 'filter' && dialog.open == true"
    />
    <CreateRealEstateCard
      @createNewContact="openContactModal"
      @close="closeDialogSimple"
      :onCreate="onCreateEstate"
      v-if="dialog.type == 'create' && dialog.open == true"
    />
    <RealEstateSlider
      v-if="dialog.type == 'card'"
      :data="dialog.data"
      :onEdit="onEdit"
      :openMediaSlider="openDialog"
      :openDemandSlider="openDemandSlider"
      :fetchContactById="fetchContactById"
      :uploadSuccessHandler="uploadDocSuccess"
      :updateEstate="updateEstate"
      :refreshEstate="fetchSpecificEstate"
      :onHandleDelete="onDelete"
      @close="closeDialogIfOriginal"
    />

    <MediaSlider
      v-if="dialog.type == 'media'"
      :data="dialog.data"
      :drawer="dialog.open"
      :close="openDialog"
      :onEdit="openDialog"
    />
    <!-- <RealEstateEdit
      :onCloseSimple="closeDialogSimple"
      :onClose="closeDialog"
      :refreshEstate="fetchSpecificEstate"
      :data="dialog.data"
      :onView="openRealEstateSlider"
      :updateEstate="updateEstate"
      :uploadDocSuccessHandler="uploadDocSuccess"
      :updateDocSuccessHandler="updateDocSuccess"
      :deleteDocSuccessHandler="deleteDocSuccess"
      v-if="dialog.type == 'edidddt'"
    /> -->
    <EditRealEstateCard
      :onCloseSimple="closeDialogSimple"
      :onClose="closeDialog"
      :refreshEstate="fetchSpecificEstate"
      :data="dialog.data"
      :onView="openRealEstateSlider"
      :updateEstate="updateEstate"
      :uploadDocSuccessHandler="uploadDocSuccess"
      :updateDocSuccessHandler="updateDocSuccess"
      :deleteDocSuccessHandler="deleteDocSuccess"
      v-if="dialog.type == 'edit'"
    />
    <ContactSlider
      v-if="dialog.type == 'profileSlider' && dialog.open"
      :data="personalData[0]"
      :editProfile="true"
      :closeHandler="() => (dialog.type = 'card')"
      :updateIsSavedHandler="() => true"
      :openEditSlider="() => (dialog.type = 'editContactSlider')"
      :openRealEstateSlider="openRealEstateSlider"
    />
    <EditSlider
      v-if="dialog.type == 'editContactSlider' && dialog.open"
      :editData="personalData[0]"
      :closeHandler="() => (dialog.type = 'card')"
      :closeEditDialog="() => (dialog.type = 'card')"
      :fetchSpecificContact="updateEstate"
    />
    <DemandSlider
      v-if="dialog.type == 'demand' && dialog.open"
      :data="dialog.data"
      :canEdit="false"
      :canDelete="false"
      :closeDemandSlider="() => openRealEstateSlider(originalEstate, true)"
      :fetchContactById="fetchContactById"
      :getSpecificEstate="getSpecificEstate"
      :fetchDemands="(newData) => fetchDemand(dialog.data._id)"
      :loading="loading"
      :allowEvaluation="false"
      :viewCommentsBtn="false"
    />
    <v-dialog
      v-model="deleteDialog.open"
      v-if="deleteDialog.type == 'delete'"
      width="500"
    >
      <DeletePopup
        :onRemove="onDeleteEstate"
        :onCancel="closeDeleteDialog"
        cancelText="Ακύρωση"
        deleteText="Ναι, είμαι σίγουρος"
        :content="deleteDialog.content"
        :item="deleteDialog.data"
        :loading="deleteLoading"
      />
    </v-dialog>
    <v-dialog v-model="messageDialog.open" width="500">
      <PopupMessage
        :content="messageDialog.content"
        :type="messageDialog.type"
      />
    </v-dialog>
    <v-row
      v-if="data.length > 0"
      :class="mobileBreakpoint == true ? 'pb-16' : 'pb-6'"
    >
      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12" v-if="mobileBreakpoint == true">
        <v-pagination
          class="main-pgt"
          v-model="options.page"
          :total-visible="6"
          @input="fetchRealEstate"
          :length="totalPages"
        >
        </v-pagination>
      </v-col>
    </v-row>
    <v-footer
      inset
      padless
      absolute
      style="background-color: #ebebeb"
      v-if="data.length > 0"
      :class="mobileBreakpoint == true ? 'pb-10' : ''"
    >
      <v-row class="mb-0" style="max-width: 100% !important">
        <v-col align="left" class="main-pgt pt-4 footerCl">
          {{ $t("footerP1") }} {{ options.page ? options.page : 0 }}
          {{ $t("footerP2") }} {{ options.items ? options.items : 0 }}
          {{ $t("footerP3") }} {{ totalResult ? totalResult : 0 }}
          {{ $t("footerP4") }}
        </v-col>
      </v-row>
    </v-footer>
  </v-col>
</template>

<script>
/* 
  Name:RealEstate
  Description: RealEstate view is the main page for the real estates. In this page we have estates cards, report, filters and we can also create, edit and delete a estate.
  
  Components Used:
    CustomTable
    HeaderTitle
    FilterRealEstate
    Icon
    RealEstateCard
    RealEstateCardMaps
    CreateRealEstateCard
    RealEstateSlider
    EditRealEstateCard
    DeletePopup
    LoaderGif
    HeaderColumns
    Location
    ContactSlider
    DemandSlider
    SortColumns
    EditSlider
    MediaSlider
    PopupMessage
    FilterTag
    ServerProblem
    NoData

  Endpoints Functions Called:
  -RealEstateRepo 
    getReportData
    update
    get
    getId
    delete

  -CompaniesRepo
    get

  -ContactRepo
    get
    getId

*/
import { mapGetters, mapState, mapActions } from "vuex";
import { Icon } from "@iconify/vue2";
import Repository from "@/services/Repository";
import realEstateData from "@/static/realEstate/realEstate";
import RealEstateHelper from "@/helpers/RealEstate/realEstates.js";
import AuthHelper from "@/helpers/Core/auth.js";
import CoreHelper from "@/helpers/Core/core";
import HeaderColumns from "@/components/CustomTable/HeaderColumns.vue";
import SortColumns from "@/components/CustomTable/SortColumns.vue";
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import FilterRealEstate from "@/components/RealEstate/Filters/Filter.vue";
import CreateRealEstateCard from "@/components/RealEstate/CreateRealEstateCard.vue";
import CustomTable from "@/components/CustomTable/CustomTable.vue";
import RealEstateCard from "@/components/RealEstate/RealEstateCard.vue";
import RealEstateCardMaps from "@/components/RealEstate/RealEstateCardMaps.vue";
import RealEstateSlider from "@/components/RealEstate/RealEstateSlider/RealEstateSlider.vue";
import MediaSlider from "@/components/RealEstate/MediaSlider";
import EditRealEstateCard from "@/components/RealEstate/EditRealEstateCard.vue";
import DemandSlider from "@/components/Demands/Slider/DemandSlider.vue";
import DeletePopup from "@/components/Popups/DeletePopup.vue";
import PopupMessage from "@/components/Popups/PopupMessage.vue";
import LoaderGif from "@/components/Popups/LoaderGif.vue";
import Location from "@/components/Maps/Location.vue";
import ContactSlider from "@/components/Contacts/ContactSlider/ContactSlider";
import EditSlider from "@/components/Contacts/EditContact/EditSlider.vue";
import ContactsHelper from "@/helpers/Contacts/contacts.js";
import FilterTag from "@/components/Filters/FilterTag.vue";
import ServerProblem from "@/components/Popups/ServerProblem.vue";
import DemandHelper from "@/helpers/Demand/demand.js";
import NoData from "@/components/Popups/NoData.vue";
import i18n from "@/i18n/i18n";
const RealEstateRepo = Repository.get("realEstate");
const CompaniesRepo = Repository.get("companies");
const ContactRepo = Repository.get("contacts");
const RequestsDemandRepo = Repository.get("reqDemand");

export default {
  name: "RealEstate",
  components: {
    CustomTable,
    HeaderTitle,
    FilterRealEstate,
    Icon,
    RealEstateCard,
    RealEstateCardMaps,
    CreateRealEstateCard,
    RealEstateSlider,
    EditRealEstateCard,
    DeletePopup,
    LoaderGif,
    HeaderColumns,
    Location,
    ContactSlider,
    SortColumns,
    EditSlider,
    DemandSlider,
    MediaSlider,
    PopupMessage,
    FilterTag,
    ServerProblem,
    NoData,
  },
  async created() {
    await this.fetchRealEstate();
    this.companyList = await this.fetchCompany();
    this.$nextTick(() => {
      this.getHeight();
    });
  },
  beforeDestroy() {
    this.clearFilter();
  },
  data() {
    return {
      personalData: null,
      estateOpened: null,
      demandsData: null,
      filterSelection: null,
      contactDialogStatus: false,
      dataFoundStatus: null,
      viewChoice: 0, //0:card, 1:Report, 2:Map
      originalEstate: {},
      options: {
        page: 1,
        items: 20,
      },
      companyList: [],
      filterObject: [{}, []],
      loading: false,
      expListloader: false,
      totalResult: 0,
      totalPages: 0,
      data: [],
      deleteDialog: {
        open: false,
        data: null,
        type: null,
        content: null,
      },
      filterArray: [
        {
          text: "all",
          val: 0,
        },
        {
          text: "myEstates",
          val: 1,
        },
        {
          text: "favorites",
          val: 2,
        },
      ],
      messageDialog: {
        type: null,
        open: false,
        content: null,
      },
      deleteLoading: false,
      headers: realEstateData.headers,
      headerColumns: realEstateData.headers,
      templates: realEstateData.templates,
      dialog: {
        open: false,
        type: null,
        data: null,
      },
      height: null,
      windowHeight: null,
      windowWidth: null,
    };
  },
  mounted() {
    window.addEventListener("resize", this.getHeight);
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  watch: {
    viewChoice() {
      this.$nextTick(() => {
        this.getHeight();
      });
    },
    mobileBreakpoint() {
      if (this.mobileBreakpoint == true) this.viewChoice = 0;
    },
  },
  computed: {
    ...mapGetters(["getItemsList"]),
    ...mapState({
      realEstateFilter: (state) => state.realEstate,
      userDetails: (state) => state.auth.userDetails,
    }),
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    getTableHeight() {
      return this.windowHeight - this.height - 130 > 275 &&
        this.windowWidth > 600
        ? this.windowHeight - this.height - 130
        : this.windowWidth > 600
        ? 275
        : 400;
    },
    getReportHeight() {
      return this.windowHeight - this.height - 90;
    },
    filterEstate: {
      get() {
        return this.realEstateFilter.filterEstate;
      },
      set(value) {
        this.onSetFilterEstate(value);
      },
    },
    reportHeaderColumns() {
      return this.headerColumns.map((m) => {
        m.text = i18n.t(m.text);
        return m;
      });
    },
    sort() {
      let lSort = this.headers.filter((f) => {
        return f.sort;
      });
      return lSort.length == 0 ? [{}] : lSort;
    },
    tagFilterArray() {
      return this.filterObject[1];
    },
    isAuthAddEstate() {
      console.log("userDetails", this.userDetails.type);
      return (
        this.userDetails &&
        this.userDetails.Type &&
        this.userDetails.Type.type.length > 0 &&
        this.userDetails.Type.type.includes("Συνεργάτης")
      );
    },
  },
  methods: {
    ...mapActions([
      "onSetRefreshPage",
      "onSetArea",
      "clearFilter",
      "onSetFilterEstate",
    ]),
    editValuationHandler(data, newEval) {
      data.Client.customer_evaluation = newEval;
    },
    async openDemandSlider(id) {
      await this.fetchDemand(id);
      this.openDialog("demand", this.demandsData);
    },
    async fetchDemand(id) {
      try {
        let object = {};
        let array = [];
        let resp = [];

        this.loading = true;
        let params = {
          page: 0,
          items: 1,
        };
        object = { $and: [{ _id: id }] };
        resp = await RequestsDemandRepo.get(object, params);

        if (resp.data != undefined && resp.data.requests != undefined) {
          if (resp.data.requests.length > 0) {
            this.dataFoundStatus = "true";
            let initData = DemandHelper.initData(resp.data, this.currency);
            array = initData[0];
            this.unReadCNT = initData[1];
            this.demandsData = array[0];
          }
        }
        this.$nextTick(() => {
          this.getHeight();
        });
        this.loading = false;
      } catch (e) {
        this.dataFoundStatus = "false";
        this.loading = false;
        this.$nextTick(() => {
          this.getHeight();
        });
        throw e;
      }
    },
    parseBoolean(val) {
      return CoreHelper.parseBoolean(val);
    },
    openEmail() {
      window.location.href = "mailto:";
    },
    getHeight() {
      var element = document.getElementById("topAreaID");
      var positionInfo = element.getBoundingClientRect();
      this.height = positionInfo.height;
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    async fetchCompany() {
      try {
        const resp = await CompaniesRepo.get({
          action: "get_companies",
        });
        return resp.data;
      } catch (err) {
        console.error(err, "Error");
      }
    },
    async fetchContactById(id) {
      let object = { _id: id };
      let params;
      params = {
        page: 0,
        items: 1,
      };
      var resp = await ContactRepo.get(object, params);
      if (resp.data != undefined && resp.data.contacts != undefined) {
        if (resp.data.contacts.length > 0) {
          this.personalData = resp.data.contacts.map((c) => {
            return ContactsHelper.initDataContact(c);
          });
        }
      }
      this.dialog.type = "profileSlider";
    },
    async fetchSpecificContact(id, openEdit) {
      let params;
      params = {
        page: 0,
        items: this.options.items,
      };
      let resp = await ContactRepo.getId(id, params);

      if (
        resp &&
        resp.data &&
        resp.data.contacts &&
        resp.data.contacts.length
      ) {
        const index = this.data.findIndex((d) => {
          return d._id === resp.data.contacts[0]?._id;
        });
        if (index != -1) {
          this.data[index] = resp.data.contacts[0];

          this.data[index] = ContactsHelper.initDataContact(this.data[index]);
          if (openEdit == true) {
            this.dialog.data = { ...this.data[index] };
          }
        } else {
          this.data.unshift(resp.data.contacts[0]);
          this.data[0] = resp.data.contacts[0];
          this.data[0] = ContactsHelper.initDataContact(this.data[0]);
        }
        if (openEdit != true) {
          this.openDialog("profileSlider", resp.data.contacts[0]);
        }
        this.tableKey++;
      } else {
        this.closeDialog();
      }
    },
    updateHeaderCols(headerCols) {
      this.headerColumns = headerCols;
    },
    updateSortCols(sortCols) {
      if (sortCols.length > 0) {
        this.headers.map((m) => {
          let sortCol = sortCols.find((col) => {
            if (col.value == m.value) {
              return col;
            }
          });
          m.sort = sortCol != undefined ? sortCol.sort : "";
          return m;
        });
      } else {
        this.headers.map((m) => {
          m.sort = "";
          return m;
        });
      }
      this.fetchRealEstate();
    },
    async exportListClicked() {
      try {
        this.loading = true;
        this.expListloader = true;
        let object;
        this.filterObject = await RealEstateHelper.setFilterObject(
          this.realEstateFilter,
          this.viewChoice
        );
        object = this.filterObject[0];
        let params;
        if (Object.keys(this.sort[0]).length === 0) {
          params = {
            page: this.options.page - 1,
            items: this.options.items,
          };
        } else {
          let lSort = await CoreHelper.customStringify(
            this.sort[0].value,
            this.sort[0].sort == "asc" ? 1 : -1 /* 1 Asc, -1 Desc */
          );
          params = {
            page: this.options.page - 1,
            items: this.options.items,
            sort: lSort,
          };
        }
        await RealEstateRepo.getReportData({ $and: object, $or: {} }, params);
        this.loading = false;
        this.expListloader = false;
      } catch (e) {
        this.loading = false;
        this.expListloader = false;
        throw e;
      }
    },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type: type,
        data: data,
      };
    },
    closeDialog(type, id) {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
      if (type == "genericSearch") this.fetchRealEstate();
      else if (type == "specificSearch" && id != undefined && id != null)
        this.fetchSpecificEstate(id);
    },
    closeDialogIfOriginal() {
      if (this.dialog?.data?.isOriginal == true) {
        this.dialog = {
          open: false,
          type: null,
          data: null,
        };
      } else if (
        this.originalEstate &&
        this.dialog?.data?._id == this.originalEstate?._id
      ) {
        this.dialog = {
          open: false,
          type: null,
          data: null,
        };
      } else if (this.originalEstate && this.originalEstate._id) {
        this.openDialog("card", this.originalEstate);
      } else {
        this.dialog = {
          open: false,
          type: null,
          data: null,
        };
      }
    },
    closeDialogSimple() {
      if (this.dialog?.daga?.isOriginal == true) this.originalEstate = {};
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    openDeleteDialog(type, data, content) {
      this.deleteDialog = {
        open: true,
        type: type,
        data: data,
        content: content,
      };
    },
    closeDeleteDialog() {
      this.deleteDialog = {
        open: false,
        type: null,
        data: null,
        content: null,
      };
    },
    openMessageDialog(type, content) {
      this.messageDialog = {
        open: true,
        type: type,
        content: content,
      };
    },
    closeMessageDialog() {
      this.messageDialog = {
        open: false,
        type: null,
        content: null,
      };
    },
    async onCreateEstate(id) {
      console.log("ARRIVED 1");
      if (id) {
        console.log("ARRIVED 2");
        await this.closeDialogSimple();
        await this.fetchSpecificEstate(id, "add");
      }
    },
    async optionsRefresh() {
      this.options.page = 1;
      await this.fetchRealEstate();
    },
    createBtn() {
      this.openDialog("create", null);
    },
    filtersBtn(type) {
      this.filterSelection = type;
      this.openDialog("filter", null);
    },

    async deleteFilter(attrHead, attrType, attrValue, attrIndex) {
      if (attrType == "string") {
        for (let i = 0; i < attrHead.length; i++)
          await this.$store.dispatch(attrHead[i].action, null);
        if (attrHead[0].name == "category")
          await this.$store.dispatch("onSetSubCategory", null);
      } else if (attrType == "array") {
        let filteredArray = this.realEstateFilter[attrHead[0].name];
        filteredArray.splice(attrIndex, 1);
        for (let i = 0; i < attrHead.length; i++) {
          await this.$store.dispatch(attrHead[i].action, filteredArray);
        }
      } else if (attrType == "arrayByVal") {
        let findArray = this.realEstateFilter[attrHead[0].name];
        let indexVal = findArray.findIndex((object) => {
          return object == attrValue;
        });
        findArray.splice(indexVal, 1);
        for (let i = 0; i < attrHead.length; i++) {
          await this.$store.dispatch(attrHead[i].action, findArray);
        }
      } else if (attrType == "commaSeparated") {
        let commaString = this.realEstateFilter[attrHead[0].name];
        let findArray = commaString.split(",");
        let indexVal = findArray.findIndex((object) => {
          return object == attrValue;
        });
        findArray.splice(indexVal, 1);
        commaString = findArray.join();
        for (let i = 0; i < attrHead.length; i++) {
          await this.$store.dispatch(
            attrHead[i].action,
            commaString.length == 0 ? null : commaString
          );
        }
      }
      await this.fetchRealEstate();
    },
    clearAllFilter() {
      this.$store.dispatch("clearFilter");
      this.fetchRealEstate();
    },
    openRealEstateSlider(data, isOriginal) {
      if (isOriginal) {
        this.originalEstate = data;
        this.openDialog("card", { ...data, isOriginal: true });
      } else {
        this.openDialog("card", data);
      }
      this.estateOpened = data["_id"] ? data["_id"] : null;
    },
    async getSpecificEstate(id) {
      let resp = await RealEstateRepo.getId(id);
      if (resp && resp.data && resp.data.estates) {
        this.openRealEstateSlider(
          resp.data.estates[0] ? resp.data.estates[0] : null
        );
      }
    },
    async changeStatus(id, action) {
      let body = {};
      body["Status"] = action ? "Ενεργό" : "Μη Ενεργό";
      body["IsApproved"] = true;
      await RealEstateRepo.update(id, body);
      await this.fetchRealEstate();
    },
    openContactModal() {
      this.contactDialogStatus = true;
    },
    closeContactModal() {
      this.contactDialogStatus = false;
    },
    async fetchRealEstate() {
      this.loading = true;
      try {
        let object;
        this.filterObject = await RealEstateHelper.setFilterObject(
          this.realEstateFilter,
          this.viewChoice
        );
        object = this.filterObject[0];
        this.realEstateFilter.refreshPage ? (this.options.page = 1) : null;
        let params;
        if (Object.keys(this.sort[0]).length === 0) {
          params = {
            page: this.options.page - 1,
            items: this.options.items,
          };
        } else {
          let lSort = await CoreHelper.customStringify(
            this.sort[0].value,
            this.sort[0].sort == "asc" ? 1 : -1 /* 1 Asc, -1 Desc */
          );
          params = {
            page: this.options.page - 1,
            items: this.options.items,
            sort: lSort,
          };
        }
        var resp = await RealEstateRepo.get({ $and: object, $or: {} }, params);
        this.data =
          resp == undefined ||
          resp.data == undefined ||
          resp.data.estates == undefined
            ? []
            : resp.data.estates;
        if (this.data.length > 0) {
          this.dataFoundStatus = "true";
          this.totalResult = resp.data.results;
          this.totalPages = Math.ceil(this.totalResult / this.options.items);
          this.data.map((m) => {
            if (m.floors && m.floors.length > 0) {
              m.floors.map((mf) => {
                if (![null, undefined, ""].includes(mf.FloorLiteral)) return mf;
                else if (![null, undefined, ""].includes(mf.FloorValue)) {
                  mf.FloorLiteral =
                    this.$store.getters.getFloorDescriptionByVal(
                      Number(mf.FloorValue)
                    );
                } else if (![null, undefined, ""].includes(mf.Value)) {
                  mf.FloorLiteral =
                    this.$store.getters.getFloorDescriptionByVal(
                      Number(mf.Value)
                    );
                } else if (!isNaN(mf)) {
                  mf = {
                    FloorLiteral: this.$store.getters.getFloorDescriptionByVal(
                      Number(mf)
                    ),
                    FloorValue: mf,
                  };
                }
                return mf;
              });
            }
          });
        } else {
          this.dataFoundStatus = "false";
          this.totalResult = 0;
          this.totalPages = 0;
        }
        if (this.realEstateFilter.refreshPage) this.onSetRefreshPage(false);
        this.loading = false;
      } catch (e) {
        this.dataFoundStatus = "error";
        this.data = [];
        this.loading = false;
        this.totalResult = 0;
        this.totalPages = 0;
        if (this.realEstateFilter.refreshPage) this.onSetRefreshPage(false);
        throw e;
      }
    },
    async fetchSpecificEstate(id, action) {
      console.log("ARRIVED HERE");
      let resp = await RealEstateRepo.getId(id);
      if (resp && resp.data && resp.data.estates) {
        if (action == "add") this.onEdit(resp.data.estates[0]);
        const index = this.data.findIndex((d) => {
          return d._id === resp.data.estates[0]?._id;
        });
        if (index != -1) {
          this.data[index] = resp.data.estates[0];
          this.dialog.data = this.data[index];
        } else this.data.unshift(resp.data.estates[0]);
      }
    },
    onDelete(item) {
      let content = `Πρόκειται να διαγράψετε το ακίνητο με κωδικό ${item.EstateCode}`;
      this.openDeleteDialog("delete", item, content);
    },
    onEdit(item) {
      this.estateOpened = item["_id"] ? item["_id"] : null;
      this.openDialog("edit", item);
    },
    async onDeleteEstate(item) {
      try {
        this.deleteLoading = true;
        await RealEstateRepo.delete(item["_id"]);
        this.fetchRealEstate();
        this.deleteLoading = false;
      } catch (e) {
        this.deleteLoading = false;
        throw e;
      }
      this.closeDeleteDialog();
      this.openMessageDialog(
        "success",
        `Μόλις διαγράψατε το ακίνητο με κωδικό ${item.EstateCode}`
      );
      setTimeout(() => {
        this.closeMessageDialog();
      }, 2400);
    },
    updateEstate(estateId, objProp, objVal) {
      const index = this.data.findIndex((d) => {
        return d._id === estateId;
      });
      if (index != -1) {
        this.data[index][objProp] = objVal;
        try {
          this.dialog.data = {
            ...this.dialog.data,
            [objProp]: objVal,
          };
        } catch (err) {
          console.error(err);
        }
      }
    },
    uploadDocSuccess(estateId, docTypeProp, objVal) {
      const index = this.data.findIndex((d) => {
        return d._id === estateId;
      });
      if (index != -1) {
        if (this.data[index]["documents"] == undefined) {
          this.data[index] = {
            ...this.data[index],
            documents: {},
          };
        }
        if (this.data[index]["documents"][docTypeProp])
          this.data[index]["documents"][docTypeProp].push(objVal);
        else {
          let newObj = {};
          newObj[docTypeProp] = [objVal];
          this.data[index]["documents"] = {
            ...this.data[index]["documents"],
            ...newObj,
          };
          this.dialog.data = {
            ...this.dialog.data,
            documents: this.data[index]["documents"],
          };
        }
      }
    },
    updateDocSuccess(estateId, docTypeProp, objIndx, objVal) {
      const index = this.data.findIndex((d) => {
        return d._id === estateId;
      });
      if (
        index != -1 &&
        this.data[index]["documents"][docTypeProp] != undefined
      )
        this.data[index]["documents"][docTypeProp][objIndx].visibility =
          objVal.visibility;
    },
    deleteDocSuccess(estateId, docTypeProp, objIndx) {
      const index = this.data.findIndex((d) => {
        return d._id === estateId;
      });
      this.data[index]["documents"][docTypeProp].splice(objIndx, 1);
      this.dialog.data = {
        ...this.dialog.data,
        documents: this.data[index]["documents"],
      };
    },
    isAuthorized(action) {
      return AuthHelper.authorization(
        action,
        this.userDetails["_id"],
        this.userDetails["_id"]
      );
    },
  },
};
</script>
<style scoped>
.btn-custom {
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 15px;
  background-color: white;
}

.v-btn-toggle--group > .v-btn.v-btn {
  border-color: #263573 !important;
}

.estateOpened {
  background-color: rgba(88, 102, 155, 0.03);
  border: 2px solid #8893bf;
  mix-blend-mode: multiply;
}

.v-btn-toggle > .v-btn.v-btn {
  border-radius: 5px !important;
  border-width: thin !important;
  background-color: white !important;
}

.gray-btn {
  background-color: #dadada !important;
  color: #ffffff !important;
  font-size: 14 !important;
  border: unset !important;
  text-transform: capitalize !important;
}

#active-button {
  background-color: #263573 !important;
}

.footerCl {
  color: #3a3a40;
}

.main-pgt {
  font-size: 14px !important;
  /* font-family:CenturyGothicBold; */
}

.main-select {
  color: #263573 !important;
  border-color: #253472 !important;
  /* background-color: #263573; */
}

.theme--light.v-select .v-select__selections {
  color: #263573 !important;
}

.noScrollBar::-webkit-scrollbar {
  display: none;
}

.realEstCards {
  overflow: auto;
}

@media only screen and (max-width: 545px) {
  .topFirstRow,
  .topSecondRow {
    justify-content: flex-start !important;
  }
}

@media only screen and (max-width: 1000px) {
  .hideOnMedia {
    display: none !important;
  }
}

@media only screen and (max-width: 1700px) {
  .rightHRow {
    max-width: 500px;
  }
}

@media only screen and (max-width: 1389px) {
  .HRow {
    flex-direction: column-reverse;
  }

  .HCol {
    padding-top: 0px !important;
  }
}

@media only screen and (max-width: 878px) {
  .topRow {
    flex-direction: column;
  }

  .topLeftCol {
    padding-top: 0px !important;
    padding-bottom: 6px !important;
    padding-left: 15px !important;
  }
}

@media only screen and (max-width: 753px) {
  .locRow {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 600px) {
  .realEstCards {
    overflow: unset !important;
  }
}
</style>
